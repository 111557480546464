<template>
    <div class="followTable" @click="hideFollowModal" style="height:100%">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>招生咨询</a-breadcrumb-item>
            <a-breadcrumb-item>跟进记录</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="followTable-board">
            <div class="clearfix table-tools" style="background:#fff;margin-bottom:10px">
                <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline' @submit="searchList" style="padding-left: 5px;">
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                                <a-select-option :value="0">我的</a-select-option>
                                <a-select-option :value="1">我下属的</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <template slot="title">
                                <span>时间筛选</span>
                            </template>
                            <a-range-picker
                                :ranges="rangesData"
                                :value='rangesValue'
                                :disabled-date="disabledDate" 
                                allowClear
                                style="width: 230px"
                                @change="handleTime" />
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-input v-model='searchParams.search.log' style="width: 160px" allowClear placeholder="请输入关键词"></a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-select v-model="searchParams.search.event_id" style="width: 150px" placeholder="请选择事件" allowClear showSearch :filterOption="filterOption">
                                <a-select-option v-for="(item,index) in eventList" :key="index" :value="item.event_id">{{item.event_name}}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <template slot="title">
                                <span>来源渠道</span>
                            </template>
                            <a-tree-select
                                v-model="searchParams.search.channel_id"
                                :tree-data="parentChannels"
                                tree-checkable
                                allowClear
                                treeNodeFilterProp="title"
                                :maxTagCount='1'
                                style="width: 240px;"
                                :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                                :show-checked-strategy="SHOW_ALL"
                                placeholder="请选择来源渠道"
                            />
                            </a-tooltip>
                        </a-form-item>
                        <!-- <a-form-item>
                            <a-radio-group :value="searchParams.search.is_back_visit">
                                <a-radio-button @click="handleTypeButChange('1')" value="1">
                                    回访跟进
                                </a-radio-button>
                            </a-radio-group>
                        </a-form-item> -->
                        <a-form-item>
                            <a-button @click="searchList" html-type="submit" :loading="loading" type="primary">搜索</a-button>
                        </a-form-item>
                        <a-form-item>
                            <a-popover  placement="bottom" trigger="click" v-model="visible">
                            <template slot="content">
                                <div class="more-search">
                                <a-form layout='inline'>
                                    <a-form-item class="block-line" label="所属校区">
                                        <a-select v-model='searchParams.search.studio_id' mode="multiple" :maxTagCount="1" :maxTagTextLength='5'  allowClear showSearch placeholder="请选择校区" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="课程顾问">
                                        <a-select mode="multiple" v-model='searchParams.search.course_consultant_id' allowClear showSearch placeholder="请选择课程顾问" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="跟进类型">
                                        <a-select v-model='searchParams.search.log_type' allowClear placeholder="请选择跟进类型">
                                        <a-select-option value="leads">线索跟进</a-select-option>
                                        <a-select-option value="customer">客户跟进</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="跟进状态">
                                        <a-select mode="multiple" v-model='searchParams.search.task_step' allowClear placeholder="请选择跟进状态">
                                        <a-select-option v-for="(d, index) of dealStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>

                                    <a-form-item class="block-line" label="录入人员">
                                        <a-select v-model='searchParams.search.source_employee_id' allowClear showSearch @search="handleSearch" placeholder="请选择录入人员" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>

                                    <a-form-item class="block-line" label="录入时间">
                                        <a-range-picker
                                        :ranges="rangesData"
                                        :value='createdValue'
                                        :disabled-date="disabledDate"
                                        allowClear
                                        @change="(val,time)=>handleTime2(val,time,'created')" />
                                    </a-form-item>

                                </a-form>
                                <div class="more-search__buttons">
                                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                                    <a-button @click="reset">重置</a-button>
                                    <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>

                                </div>
                                </div>
                            </template>
                                <a-badge>
                                    <a-button>更多<a-icon type="down" /></a-button>
                                </a-badge>
                            </a-popover>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="boards">
                <ul class="boards-ul">
                    <li v-for="(item,index) in list" v-if="item.show" :key="item.value" class="boards-item">
                        <vue-element-loading :active="item.loading && item.arr.length==0" color="#00cca2" spinner="spinner"/>
                        <div class="boards-item-nav">
                            <span style="font-size: 15px;display: inline-block;padding-bottom: 5px;color: #333;">{{item.label}}</span>
                            <div>{{item.arr.length}}/{{item.count}}</div>
                        </div>
                        <div
                            class="boards-item-box"
                            v-infinite-scroll="e=>handleInfiniteOnLoad(e,item,index)"
                            :infinite-scroll-disabled="item.busy"
                            :infinite-scroll-distance="10"
                        >
                            <a-list item-layout="horizontal" :data-source="item.arr" :split="false">
                                <a-list-item slot="renderItem" slot-scope="it, j">
                                    <div @click.stop="showFollowModal(it)" style="width: 100%" @mouseover="hadleOver(index,j)" @mouseout="handleOut">
                                        <a-card hoverable class="boards-card" style="width: 100%">
                                            <div class="boards-card-nav boards-mb">
                                                <div style="flex:1">
                                                    <div>
                                                        <span>#{{it.leads_detail.clue_name || it.customer_detail.customer_name}} </span>
                                                        <svg style="width:16px;height: 16px;vertical-align: middle;" aria-hidden="true" v-show="it.customer_detail && it.customer_detail.is_bind_qywx">
                                                            <use xlink:href="#icon-qiyeweixin"></use>
                                                        </svg>
                                                        <span style="font-size:12px;word-break: break-all;"> : {{it.content}} ({{it.created_at}})</span>
                                                    </div>
                                                    <div>
                                                        <div  style="font-size:12px;color:#ccc" v-if="it.studio_name">#{{it.studio_name}} </div>
                                                        <div  style="font-size:12px;color:#ccc">#{{it.channel_name}} </div>
                                                        <div  style="font-size:12px;color:#ccc" v-if="it.is_back_visit == 1">#回访</div>
                                                        <!-- <span  style="font-size:12px;color:#ccc">#</span> -->
                                                        <!-- <a-tag v-if="it.studio_name" style="border-radius: 11px" class="board-mb">{{it.studio_name}}</a-tag>
                                                        <a-tag v-if="it.channel_name" style="border-radius: 11px" class="board-mb">{{it.channel_name}}</a-tag>
                                                        <a-tag v-if="it.created_at" style="border-radius: 11px" class="board-mb">{{it.created_at}}</a-tag> -->
                                                    </div>
                                                </div>
                                                <div class="boards-card-info">
                                                    <a-avatar :src="it.created_avatar" :size="30" icon="user" />
                                                    <div class="boards-card-name">
                                                        {{it.created_by}}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </a-card>
                                    </div>
                                </a-list-item>
                                <div slot="footer">
                                    <vue-element-loading :active="item.loading && item.arr.length > 0" color="#00cca2" spinner="spinner"/>
                                    <div style="text-align:center" v-if="item.arr.length == item.count && item.arr.length > 0">没有更多了~</div>
                                </div>
                            </a-list>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <followModal :authType="authType" :item="modalData" :search='searchParams.search' :leadsId='leadsId' :isTab='isTab' v-if="followVisible"/>
    </div>
</template>

<script>
    import C_ITEMS from '@/utils/items'
    import moment from 'moment'
    import ranges from "@/common/mixins/ranges"
    import infiniteScroll from 'vue-infinite-scroll'
    import authority from '@/common/mixins/authority'
    import followModal from '@/views/clientresource/modal/followModal/index'
    export default {
        name:'followLog',
        data() {
            return {
                exportLoading:false,
                loading:false,
                visible:false,
                followVisible:false,
                authType:['crm','leads'],
                modalData:{},
                leadsId:'',
                isTab:'0',
                list: [
                    { label: '未跟进', value: 0 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                    { label: '跟进中', value: 1 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                    { label: '已预约', value: 2 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                    { label: '已到访', value: 3 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                    { label: '已成单', value: 4 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                    { label: '长期跟进', value: 15 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                    { label: '暂搁', value: 5 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                    { label: '未接通', value: 6 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                    { label: '无效', value: 10 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                ],
                listF: [
                    { label: '未跟进', value: 0 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                    { label: '跟进中', value: 1 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                    { label: '已预约', value: 2 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                    { label: '已到访', value: 3 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                    { label: '已成单', value: 4 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                    { label: '长期跟进', value: 15 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                    { label: '暂搁', value: 5 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                    { label: '未接通', value: 6 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                    { label: '无效', value: 10 , arr:[], page:1 ,loading:false ,count:0 ,busy:true ,show:true},
                ],
                hoverIndex:[-1,-1],
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {
                        is_sub:0,
                        start_date:'',
                        end_date:'',
                    },
                    "sort": ''
                },
                parentChannels:[],
                createdValue:[],
                studios:[],
                consultants:[],
                eventList:[],
                staffList:[],
                dealStatus:C_ITEMS.dealStatus,
            }
        },
        directives: { infiniteScroll },
        mixins: [ authority, ranges],
        components: {
            followModal,
        },
        beforeCreate () {
            if(!this.$ls.get('is_sub')){
                this.$ls.set('is_sub', 0)
            }
        },
        created () {
            if(this.$route.query.start_date){
                this.searchParams.search.start_date = this.$route.query.start_date
                this.searchParams.search.end_date = this.$route.query.end_date
                this.rangesValue = [moment(this.$route.query.start_date),moment(this.$route.query.end_date)]
            }
            this.getStudio()
            this.getSourceChannel()
            this.getStaff()
            this.getConsultant()
            this.getEventList()
            this.list.forEach(item=>{
                this.getList(item)
            })
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async toExport(){
                this.exportLoading = true
                let searchData = {
                    search:this.searchParams.search,
                    sort:this.searchParams.sort
                }
                let res = await this.$store.dispatch('cFollowExportAction', searchData)
                if (res.type.indexOf('json') !== -1) {
                    var blob = new Blob([res], {
                        type: 'application/json'
                    })
                    let reader = new FileReader();
                    reader.readAsText(blob, 'utf-8')
                    reader.onload = () => {
                        var temp = JSON.parse(reader.result)
                        console.log(temp);
                        if(temp.status=='error'){
                            this.$message.error(temp.msg);
                            this.exportLoading = false
                            return false;
                        }
                    }
                }else{
                    const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                    if ('download' in document.createElement('a')) { 
                        const link = document.createElement('a')
                        link.download = `跟进记录.${moment().format('YYYY.MM.DD')}.xlsx`
                        link.style.display = 'none'
                        link.href = URL.createObjectURL(blob)
                        document.body.appendChild(link)
                        link.click()
                        URL.revokeObjectURL(link.href) 
                        document.body.removeChild(link)
                    } else { //其他浏览器
                        navigator.msSaveBlob(blob, fileName)
                    }
                }
                this.exportLoading = false
            },
            async getSourceChannel() {
                let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
                this.parentChannels = res.data
            },
            async getList(item) {
                item.loading = true
                this.loading = true;
                this.searchParams.search.is_sub = this.$ls.get('is_sub')
                let obj = {
                    task_step:item.value,
                    'page':item.page,
                    "per-page": 15,
                    search:this.searchParams.search
                }
                let res = await this.$store.dispatch('cFollowAction', {data:obj})
                item.count = res._meta.totalCount
                if(item.page == 1){
                    item.arr = res.items
                }else{
                    item.arr = [...item.arr,...res.items]
                }
                if(item.arr.length == res._meta.totalCount){
                    item.busy = true
                }else{
                    item.busy = false
                }
                item.loading = false
                this.loading = false;
            },
            async getStudio() {
                let res = await this.$store.dispatch('searchBelongStudioAction', {})
                this.studios = res.data
            },
            async getConsultant() {
                let res = await this.$store.dispatch('searchConsultantAction', {})
                this.consultants = res.data
            },
            async getEventList(){
                await this.$store.dispatch('cFollowEventAction', {})
                .then(res=>{
                    this.eventList = res.data
                })
            },
            async getStaff(val){
                let obj = {q:val}
                let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
                this.staffList = res.data
            },
            hadleOver(index,j){
                this.hoverIndex = [index,j]
            },
            handleOut(){
                this.hoverIndex = [-1,-1]
            },
            handleIsSub(e){
                this.$ls.set('is_sub', e)
                this.searchParams.search.is_sub = e
            },
            handleTime(val,time,name){
                this.rangesValue = val
                this.$forceUpdate()
                this.searchParams.search.start_date = time[0]
                this.searchParams.search.end_date = time[1]
            },
            handleTime2(val,time,name){
                this.createdValue=val
                this.$forceUpdate()
                let start_date = name + '_start_date'
                let end_date = name + '_end_date'
                this.searchParams.search[start_date] = time[0]
                this.searchParams.search[end_date] = time[1]
                console.log(this.searchParams.search)
            },
            handleInfiniteOnLoad(e,item,index){
                if(!this.list[index].busy){
                    this.list[index].page++
                    this.getList(this.list[index])
                }
            },
            closeSearch(){},
            reset(){},
            async searchList(e){
                e?e.preventDefault():''
                this.list.forEach(item=>{
                    item.page = 1
                    item.arr = []
                    item.count = 0
                    item.busy = true
                    if(this.searchParams.search.task_step && this.searchParams.search.task_step.length >0){
                        if(this.searchParams.search.task_step.indexOf(item.value) != -1){
                            item.show = true
                        }else{
                            item.show = false
                        }
                    }else{
                        item.show = true
                    }
                })
                let arr = []
                let arr1 = []
                let arr2 = []
                if(this.searchParams.search.task_step && this.searchParams.search.task_step.length >0){
                    this.searchParams.search.task_step.forEach(item=>{
                        this.list.forEach(it=>{
                            if(it.show){
                                if(item == it.value){
                                    arr1.push(it)
                                }
                            }else{
                                arr2.push(it)
                            }
                        })
                    })
                    arr = [...arr1,...arr2]
                }else{
                    arr = this.listF
                }
                await arr.forEach(item=>{
                    this.getList(item)
                })
                this.list = arr
                this.$forceUpdate()

            },
            showFollowModal(item) {
                if(item.log_type === 'leads'){
                    if(item.leads_detail.customer_id){
                        this.isTab = '1'
                        this.modalData = item.leads_detail
                    }else{
                        this.isTab = '0'
                        this.modalData = item.leads_detail
                    }
                }else{
                    this.isTab = '1'
                    this.modalData = item.customer_detail
                }
                this.leadsId = item.id
                this.followVisible = true
            },
            hideFollowModal(type) {
                this.followVisible = false
            },
            handleSearch(val){
                this.getStaff(val)
            },
            handleTypeButChange(e){
                if(this.searchParams.search.is_back_visit == e){
                    this.searchParams.search.is_back_visit = undefined
                }else{
                    this.searchParams.search.is_back_visit = e
                }
                this.searchList()
            },
        },
    }
</script>

<style lang="scss">
    .followTable-board{
        height: calc(100% - 61px);
        padding: 15px;
    }
    .boards{
        width: 100%;
        height: calc(100% - 50px);
        &-ul{
            list-style: none;
            width: 100%;
            overflow: auto;
            margin-bottom:initial;
            padding-inline-start:0px;
            display: flex;
            height: 100%;
            .ant-list-item{
                border-bottom:none !important;
                padding: 3px 0 !important;
            }
        }
        &-mb{
            margin-bottom: 5px;
        }
        &-item{
            max-width: 295px;
            height: 100%;
            background: #fff;
            border-radius: 2px;
            margin-right: 10px;
            flex-grow:0;
            flex-shrink: 0;
            flex-basis: 295px;
            box-shadow: 0 1px 3px 0 rgba(0,0,0,.05);
            padding: 5px;
            &-nav{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                h1{
                    margin-bottom: 0;
                }
            }
            &-box{
                height: calc(100% - 34px);
                overflow-y: auto;
            }
        }
        &-card{
            position: relative;
            .ant-card-body{
                padding: 10px;
            }
            &-nav{
                display: flex;
                // align-items: center;
                // justify-content: space-between;
            }
            &-info{
                // display: flex;
                // align-items: center;
                width: 60px;
                text-align: center;
            }
            &-name{
                width: 60px;
                text-align: center;
                overflow: hidden;
                font-size: 12px;
                white-space: nowrap;
                word-break: break-all;
                text-overflow: ellipsis;
            }
        }
        &-ellipsis{
            position: absolute;
            bottom: 10px;
            right: 10px;
        }
    }
</style>